import React, { useState, useEffect } from 'react';
import { fabric } from 'fabric';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import axios from 'axios';

const App = () => {
  const [images, setImages] = useState([{ link: '', file_name: 'test_image_1.jpg', case_id: 1 }]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isAnnotated, setIsAnnotated] = useState(false);
  const [annotationsData, setAnnotationsData] = useState(null);
  const [canvas, setCanvas] = useState(null);
  const [activeTool, setActiveTool] = useState(null);
  const [undoStack, setUndoStack] = useState([]);
  const [strokeColor, setStrokeColor] = useState('#ff0000');
  const [strokeWidth, setStrokeWidth] = useState(2);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  useEffect(() => {
    const newCanvas = new fabric.Canvas('annotationCanvas', {
      width: 1280,
      height: 620
    });
    setCanvas(newCanvas);

    return () => newCanvas.dispose();
  }, []);

  useEffect(() => {
    if (canvas && images.length > 0) {
      loadImageOnCanvas(images[currentImageIndex].link);
    }
  }, [canvas, currentImageIndex, images]);

  const fetchImages = async (caseId) => {
    try {
      const response = await axios.get(
        `https://claims-staging.wimwisure.com/util/photos-by-id/${caseId}`
      );
      setImages(response.data);
      setCurrentImageIndex(0);
    } catch (error) {
      toast.error('Failed to fetch images. Please try again.');
    }
  };

  const saveAnnotatedImage = async () => {
    if (!canvas) return;

    const annotatedImage = canvas.toDataURL();
    const currentImage = images[currentImageIndex];

    const byteString = atob(annotatedImage.split(',')[1]); // Decode the base64 string
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const uint8Array = new Uint8Array(arrayBuffer);

    for (let i = 0; i < byteString.length; i++) {
      uint8Array[i] = byteString.charCodeAt(i);
    }

    // Create a Blob from the ArrayBuffer
    const blob = new Blob([uint8Array], { type: 'image/png' });

    try {
      const formData = new FormData();
      formData.append('case_id', currentImage.case_id);
      formData.append('file_name', `ai_${currentImage.file_name}`);
      formData.append('annotated_image', blob, `ai_${currentImage.file_name}`);

      console.log(annotatedImage)

      const response = await axios.post(
        'https://claims-staging.wimwisure.com/util/save-ai-photo',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          }
        },
      );

      if (response.status === 200) {
        toast.success('Annotated image saved successfully!');
        setAnnotationsData(annotatedImage); // Update annotations data
        setIsAnnotated(false); // Reset annotation state
      }
    } catch (error) {
      toast.error('Failed to save annotated image. Please try again.');
    }
  };

  const loadImageOnCanvas = (imageUrl) => {
    if (!canvas) return;

    fabric.Image.fromURL(
      imageUrl,
      (img) => {
        canvas.clear();
        const scale = Math.min(1280 / img.width, 620 / img.height);
        img.scale(scale);
        img.selectable = false;
        canvas.setBackgroundImage(img, canvas.renderAll.bind(canvas));
        setIsAnnotated(false);
        setUndoStack([]);
      },
      { crossOrigin: 'anonymous' }
    );
  };

  const handleAnnotationTool = (tool) => {
    if (!canvas) return;

    setActiveTool(tool);
    canvas.isDrawingMode = false;
    canvas.off('mouse:down');

    if (tool === 'freehand') {
      canvas.isDrawingMode = true;
      canvas.freeDrawingBrush.color = strokeColor; // Set color from color picker
      canvas.freeDrawingBrush.width = strokeWidth; // Set stroke width
    } else if (tool === 'circle' || tool === 'rectangle') {
      canvas.on('mouse:down', (event) => {
        const pointer = canvas.getPointer(event.e);

        const target = canvas.findTarget(event.e, true);
        if (target && target !== canvas.backgroundImage) {
          return; // Prevent adding shape when clicking existing object
        }

        let shape;
        if (tool === 'circle') {
          shape = new fabric.Circle({
            left: pointer.x,
            top: pointer.y,
            radius: 50,
            stroke: strokeColor,
            strokeWidth: strokeWidth,
            fill: 'transparent', // Transparent fill
            selectable: true,
          });
        } else if (tool === 'rectangle') {
          shape = new fabric.Rect({
            left: pointer.x,
            top: pointer.y,
            width: 100,
            height: 50,
            stroke: strokeColor,
            strokeWidth: strokeWidth,
            fill: 'transparent', // Transparent fill
            selectable: true,
          });
        }

        canvas.add(shape);
        canvas.setActiveObject(shape);
        setUndoStack((prevStack) => [...prevStack, shape]);
        setIsAnnotated(true);

        setActiveTool(null);
        canvas.off('mouse:down');
      });
    }
  };

  const handleUndo = () => {
    if (!canvas || undoStack.length === 0) return;

    const lastShape = undoStack.pop();
    canvas.remove(lastShape);
    setUndoStack([...undoStack]);
    setIsAnnotated(true);
  };

  const handleClear = () => {
    if (!canvas) return;

    canvas.clear();
    loadImageOnCanvas(images[currentImageIndex].link);
    setIsAnnotated(false);
    setUndoStack([]);
  };

  return (
    <div className="app-container">
      <ToastContainer />
      <h1 className="app-title">Image Annotation Tool</h1>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          const caseId = e.target.elements.caseId.value;
          if (!caseId) {
            toast.error('Please enter a valid case ID.');
            return;
          }
          fetchImages(caseId);
        }}
      >
        <input type="text" name="caseId" placeholder="Enter Case ID" />
        <button type="submit">Fetch Images</button>
      </form>
      {images.length > 0 && (
        <>
          <div className="carousel" style={{ display: images[0]?.link === '' ? 'none' : 'block' }}>
            {images.map((image, index) => (
              <img
                key={index}
                src={image.link}
                alt={image.file_name}
                onClick={() => setCurrentImageIndex(index)}
                className={`thumbnail ${currentImageIndex === index ? 'selected' : ''}`}
              />
            ))}
          </div>
          <div className="annotation-container" style={{ display: images[0]?.link === '' ? 'none' : 'block' }}>
            <canvas id="annotationCanvas"></canvas>
          </div>
          <div className="toolbar" style={{ display: images[0]?.link === '' ? 'none' : 'block' }}>
            <button
              className={activeTool === 'freehand' ? 'active-tool' : ''}
              onClick={() => handleAnnotationTool('freehand')}
            >
              Freehand
            </button>
            <button
              className={activeTool === 'circle' ? 'active-tool' : ''}
              onClick={() => handleAnnotationTool('circle')}
            >
              Circle
            </button>
            <button
              className={activeTool === 'rectangle' ? 'active-tool' : ''}
              onClick={() => handleAnnotationTool('rectangle')}
            >
              Rectangle
            </button>
            <button onClick={handleUndo}>Undo</button>
            <button onClick={handleClear}>Clear All</button>
            <button onClick={saveAnnotatedImage}>Save</button>
          </div>
          <div className="shape-controls" style={{ display: images[0]?.link === '' ? 'none' : 'block' }}>
            <label>
              Stroke Color:
              <input
                type="color"
                value={strokeColor}
                onChange={(e) => setStrokeColor(e.target.value)}
              />
            </label>
            <label>
              Stroke Width:
              <input
                type="number"
                value={strokeWidth}
                min="1"
                max="10"
                onChange={(e) => setStrokeWidth(Number(e.target.value))}
              />
            </label>
          </div>
        </>
      )}
    </div>
  );
};

export default App;
